import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import TermsPages from '../../partials/Terms/About'
import { dataTermsIndique90 } from '../../data/terms/terms-indique-90'

const TermsIndique90 = () => (
  <>
    <GatsbySeo nofollow={true} noindex={true} />
    <TermsPages
      data={dataTermsIndique90}
      linkCanonical="termos-indique-e-ganhe/90"
    />
  </>
)

export default TermsIndique90
